import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import { ImageUploader } from '../../ImageUploader';
import './Info.scss';



/*

    {
        "type": "info",
        "title": "Built for professionals",
        "text_color": "dark",
        "bg_desktop": "linear-gradient(157.02deg, #F4F4F4 22.38%, #6F8090 193.12%)",
        "description": "Phasellus vitae venenatis turpis, vitae blandit erat. In at semper felis, ut facilisis nunc. Vivamus eu quam risus. Ut tempor est et vulputate eleifend. Vivamus tempor ut elit in sollicitudin. ",
        "description_accent": false,
        "pic": false,
        "video": "https://cdn.staticcrate.com/stock-hd/effects/footagecrate-space-slipstream-loop-s.mp4"
    }

*/

function Info(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;
    const [title, setTitle] = useState(props.title??'');
    const [text_color, setTextColor] = useState(props.text_color??'dark');
    const [bg_desktop, setBgDesktop] = useState(props.bg_desktop??'');
    const [bg_mobile, setBgMobile] = useState(props.bg_mobile??'');
    const [description, setDescription] = useState(props.description??'');
    const [description_accent, setDescriptionAccent] = useState(props.description_accent??'');
    const [pic, setPic] = useState(props.pic??'');
    const [video, setVideo] = useState(props.video??'');


    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState,  title:title.length>0?title:false, text_color, bg_desktop, bg_mobile, description, description_accent, pic, video});
        }
    }, [blockState, title, text_color, bg_desktop, bg_mobile, description, description_accent, pic, video]);




    return (
        <Block className="info-block" title="Info Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <SelectButton value={text_color} onChange={(e) => setTextColor(e.value)} options={["dark", "light"]} />
            <ImageUploader 
                chooseLabel="Choose a Desktop bg image"
                uploadCallback={setBgDesktop}
                image_url={bg_desktop}
            />
            <ImageUploader 
                chooseLabel="Choose a Mobile bg image"
                uploadCallback={setBgMobile}
                image_url={bg_mobile}
            />
            <InputTextarea placeholder="Set a Description" autoResize value={description} onChange={(e) => setDescription(e.target.value)} rows={5} cols={60} />
            <InputTextarea placeholder="Set a Description Accent" autoResize value={description_accent} onChange={(e) => setDescriptionAccent(e.target.value)} rows={5} cols={60} />
            <ImageUploader 
                chooseLabel="Choose a Picture"
                uploadCallback={setPic}
                image_url={pic}
            />
            <div>Or</div>
            <InputText value={video} onChange={(e) => setVideo(e.target.value)} placeholder="Video URL" />
        </Block>
    );
}




export default Info;