/*
const cloudinary = require('cloudinary').v2;

cloudinary.config({
    cloud_name: "drx5sj1j7",
    api_key: "658131837416436",
    api_secret: "tGhoiVHyJzEzqe6sgS70UzXyZWM"
});

module.exports = cloudinary;
*/
import { Cloudinary } from "@cloudinary/url-gen";

const cloudinary = new Cloudinary({
  cloud: {
    cloudName: "drx5sj1j7",
  },
  url: {
    secure: true,
  },
});

export default cloudinary;
