import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './Accordeon.scss';



/*

    {
        "type": "accordeon",
        "title": "FAQ",
        "list": [
            {
                "head": "What software is this compatible with?",
                "body": "We provide several download formats for each asset to ensure maximum compatibility with all editing suites! You can use whatever software you are most comfortable with, or already have installed. For a list of free video editing software options click here."
            },
            {
                "head": "What software is this compatible with?",
                "body": "We provide several download formats for each asset to ensure maximum compatibility with all editing suites! You can use whatever software you are most comfortable with, or already have installed. For a list of free video editing software options click here."
            },
            {
                "head": "What software is this compatible with?",
                "body": "We provide several download formats for each asset to ensure maximum compatibility with all editing suites! You can use whatever software you are most comfortable with, or already have installed. For a list of free video editing software options click here."
            },
            {
                "head": "What software is this compatible with?",
                "body": "We provide several download formats for each asset to ensure maximum compatibility with all editing suites! You can use whatever software you are most comfortable with, or already have installed. For a list of free video editing software options click here."
            }
        ]
    },

*/

function Accordeon(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    
    const [collection, setCollection] = useState(props.list??[]);
    const emptyObject = {head: '', body: ''};

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");

        if(updateCallback){
            updateCallback({status:blockState, title:!title?!!title:title, list: collection.map(item => ({head: !item.head?!!item.head:item.head, body: !item.body?!!item.body:item.body}))});
        }
    }, [blockState, title, collection]);


    const addObjectToCollection = () =>{
        setCollection(prevCollection => {
            let newCollection = [...prevCollection];
            newCollection.push({...emptyObject});
            return newCollection;
        });
    }

    const deleteObjectFromCollection = (object_index) => {
        return () => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];
                newCollection.splice(object_index, 1);
                return newCollection;
            });
        };
    }
    
    const changeItem = (index, type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }

                newCollection[index][type] = val;
                return newCollection;
            });
        }
    }



    return (
        <Block className="accordeon-block" title="Accordeon Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title Text" />

            <div className='collection'>
                {collection.map((item, index) => (
                    <div className='item' key={`item-${index}`}>
                        <div>Item {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteObjectFromCollection(index)}></i></div>
                        <InputText value={item.head} onChange={changeItem(index,"head")} placeholder="Title" />
                        <InputText value={item.body} onChange={changeItem(index,"body")} placeholder="Description" />
                    </div>
                ))}
            </div>
            <Button label="+ Add Accordeon item" text onClick={addObjectToCollection} />
        </Block>
    );
}




export default Accordeon;