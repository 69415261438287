import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { ImageUploader } from '../../ImageUploader';

import './Hero.scss';



/*

    {
        "type": "hero",
        "bg_desktop": "images/hero.bg.desktop.1.webp",
        "bg_mobile": "images/hero.bg.mobile.1.webp",
        "pretitle": "4K ACTION VFX STOCK FOOTAGE",
        "title": "Explosions at cam",
        "subtitle": "Create blockbuster action movies with professionally built 4K video effects.",
    },

*/



function Hero(props) {

    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [pretitle, setPretitle] = useState(props.pretitle??'');
    const [title, setTitle] = useState(props.title??'');
    const [subtitle, setSubtitle] = useState(props.subtitle??'');
    const [bg_desktop, setBgDesktop] = useState(props.bg_desktop??'');
    const [bg_mobile, setBgMobile] = useState(props.bg_mobile??'');

    const [blockState, setBlockState] = useState("initiated");


    useEffect(() => {
        setBlockState("completed");

        if(updateCallback){
            let data = {
                status: blockState,
                pretitle: false,
                title: false,
                subtitle: false,
                bg_desktop: false,
                bg_mobile: false,
            };
            if(pretitle.length > 0) data.pretitle = pretitle;
            if(title.length > 0) data.title = title;
            if(subtitle.length > 0) data.subtitle = subtitle;
            if(bg_desktop.length > 0) data.bg_desktop = bg_desktop;
            if(bg_mobile.length > 0) data.bg_mobile = bg_mobile;

            updateCallback(data);
        }
    }, [blockState, pretitle, title, subtitle, bg_desktop, bg_mobile]);

    return (
        <Block className="hero-block" title="Hero Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText className="w-full" value={pretitle} onChange={(e) => setPretitle(e.target.value)} placeholder="Pretitle" />
            <InputText className="w-full" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <InputText className="w-full" value={subtitle} onChange={(e) => setSubtitle(e.target.value)} placeholder="Subtitle" />
            <ImageUploader 
                className="ml-auto" 
                chooseLabel="Choose a Desktop bg image"
                uploadCallback={setBgDesktop}
                image_url={bg_desktop}
            />
            <ImageUploader 
                className="mr-auto" 
                chooseLabel="Choose a Mobile bg image"
                uploadCallback={setBgMobile}
                image_url={bg_mobile}
            />
        </Block>
    );
}




export default Hero;