import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './Links.scss';


function Links(props) {

    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;
    console.log("props.items", props);
    const [links, setLinks] = useState(props.items??[]);

    const [blockState, setBlockState] = useState(props.status??"initiated");
    

    useEffect(() => {
        setBlockState("completed");

        if(updateCallback){
            updateCallback({status:blockState,items: links});
        }
    }, [links]);


    const addNewLink = () =>{
        setLinks(prevLinks => {
            let newlinks = [...prevLinks];
            newlinks.push({label: '', url: ''});
            return newlinks;
        });
    }

    const deleteLink = (link_index) => {
        return () => {
            setLinks(prevLinks => {
                let newlinks = [...prevLinks];
                newlinks.splice(link_index, 1);
                return newlinks;
            });
        };
    }

    const changeLink = (index, type) =>{

        return (e) => {
            setLinks(prevLinks => {
                let newLinks = [...prevLinks];

                newLinks[index][type] = e.target.value;
                return newLinks;
            });
        }
    }


    return (
        <Block className="links-block" title="Quick Links Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <div className='links-list collection'>
                {links.map((link, index) => (
                    <div className='link item ' key={`link-${index}`}>
                        <div className='p-card-subtitle w-full text-left'>Link {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteLink(index)}></i></div>
                        <InputText value={link.label} className="flex-1" onChange={changeLink(index,"label")} placeholder="Label" />
                        <InputText value={link.url} className="flex-1" onChange={changeLink(index,"url")} placeholder="URL" />
                    </div>
                ))}
            </div>
            <Button label="+ Add Link" text onClick={addNewLink} />
        </Block>
    );
}




export default Links;