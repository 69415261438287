import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './Carousel.scss';



function Carousel(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [accent, setAccent] = useState(props.accent??'');
    const [spaced, setSpaced] = useState(props.spaced??false);
    const [bg_desktop, setBgDesktop] = useState(props.bg_desktop??'');
    const [bg_mobile, setBgMobile] = useState(props.bg_mobile??'');

    const [marquees, setMarquees] = useState(props.marquees??[]);

    const [blockState, setBlockState] = useState("initiated");


    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, title:title.length>0?title:false, accent:accent.length>0?accent:false, spaced, bg_desktop, bg_mobile, marquees});
        }
    }, [blockState, title, accent, spaced, bg_desktop, bg_mobile, marquees]);


    const addNewMarquee = () =>{
        setMarquees(prevMarquees => {
            let newMarquees = [...prevMarquees];
            newMarquees.push({reverse: false, items: []});
            return newMarquees;
        });
    }

    const deleteMarquee = (marquee_index) => {
        return () => {
            setMarquees(prevMarquees => {
                let newMarquees = [...prevMarquees];
                newMarquees.splice(marquee_index, 1);
                return newMarquees;
            });
        };
    }


    const addPicToMarquee = (marquee_index) => {

        return (img) => {
            setMarquees(prevMarquees => {
                let newMarquees = [...prevMarquees];

                newMarquees[marquee_index] = {...newMarquees[marquee_index], items: [...newMarquees[marquee_index].items, ...img]}; 
                return newMarquees;
            });
        };
    }

    const changeReverseProp = (marquee_index) => {

        return () => {
            setMarquees(prevMarquees => {
                let newMarquees = [...prevMarquees];

                newMarquees[marquee_index] = {...newMarquees[marquee_index], reverse: !newMarquees[marquee_index].reverse}; 
                return newMarquees;
            });
        };
    };

    const deletePicFromMarquee = (marquee_index, pic_index) => {

        return () => {
            setMarquees(prevMarquees => {
                let newMarquees = [...prevMarquees];

                let newItems = newMarquees[marquee_index].items;

                newItems.splice(pic_index, 1);

                newMarquees[marquee_index] = {...newMarquees[marquee_index], items: [...newItems]}; 

                return newMarquees;
            });
        };
    }


    return (
        <Block className="carousel-block" title="Carousel Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText className="flex-1" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title Text" />
            <InputText className="flex-1" value={accent} onChange={(e) => setAccent(e.target.value)} placeholder="Accent Text" />
            <div className="w-full flex align-items-center">
                <Checkbox  inputId="spaced" value="Cheese" onChange={()=>setSpaced(!spaced)} checked={spaced} />
                <label htmlFor="spaced" className="checkbox-label ml-2">Spaced</label>
            </div>
            <ImageUploader 
                className="ml-auto" 
                chooseLabel="Choose a Desktop bg image"
                uploadCallback={setBgDesktop}
                image_url={bg_desktop}
            />
            <ImageUploader 
                className="mr-auto" 
                chooseLabel="Choose a Mobile bg image"
                uploadCallback={setBgMobile}
                image_url={bg_mobile}
            />
            <div className='marquees-list collection'>
                {marquees.map((marquee, index) => (
                    <div className='marquee item' key={`marquee-${index}`}>
                        <div className='p-card-subtitle'>Marquee {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteMarquee(index)}></i></div>
                        {marquee.items.length>0 && 
                        <div className="flex align-items-center">
                            <Checkbox inputId={`marquee-${index}-reverse`} checked={marquee.reverse} onChange={changeReverseProp(index)} />
                            <label htmlFor={`marquee-${index}-reverse`} className="checkbox-label ml-2">Reverse Scroll Direction</label>
                        </div>
                        }
                        {marquee.items.length>0 && <div className='pic-list-title'>Image List: </div>}
                        <div className="pic-list">
                            {marquee.items.map((item, item_index) => (
                                <div className="pic" key={`marquee-${index}-img-${item_index}`} >
                                    <img src={item} />
                                    <i className="delete-pic pi pi-trash error" onClick={deletePicFromMarquee(index,item_index)}></i>
                                </div>
                            ))}
                            
                        </div>
                        <ImageUploader 
                            chooseLabel="Upload images to Marquee"
                            multiple={true}
                            uploadCallback={addPicToMarquee(index)}
                            image_url={marquee.items}
                        />
                    </div>
                ))}
            </div>
            <Button label="+ Add Marquee" text onClick={addNewMarquee} />
        </Block>
    );
}




export default Carousel;