import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './WhatsIn.scss';



function WhatsIn(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [accent, setAccent] = useState(props.accent??'');
    const [bg_desktop, setBgDesktop] = useState(props.bg_desktop??'');
    const [bg_mobile, setBgMobile] = useState(props.bg_mobile??'');

    const [collection, setCollection] = useState(props.cards??[]);
    const emptyObject = {pic: '', title: '', price: '', subtitle: '', button: '', url: '', type: []};

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, title, accent, bg_desktop, bg_mobile, cards: collection});
        }
    }, [blockState, title, accent, bg_desktop, bg_mobile, collection]);


    const addObjectToCollection = () =>{
        setCollection(prevCollection => {
            let newCollection = [...prevCollection];
            newCollection.push({...emptyObject});
            return newCollection;
        });
    }

    const deleteObjectFromCollection = (object_index) => {
        return () => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];
                newCollection.splice(object_index, 1);
                return newCollection;
            });
        };
    }


    const changeItem = (index, type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }

                newCollection[index][type] = val;
                return newCollection;
            });
        }
    }



    return (
        <Block className="whats-in-block" title="What's In Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText className="flex-1" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title Text" />
            <InputText className="flex-1" value={accent} onChange={(e) => setAccent(e.target.value)} placeholder="Accent Text" />
            <ImageUploader 
                chooseLabel="Choose a Desktop bg image"
                uploadCallback={setBgDesktop}
            />
            <ImageUploader 
                chooseLabel="Choose a Mobile bg image"
                uploadCallback={setBgMobile}
            />
            <div className='collection cards-list w-full'>
                {collection.map((item, index) => (
                    <div className='card item' key={`card-${index}`}>
                        <div className='p-card-subtitle w-full text-left'>Card {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteObjectFromCollection(index)}></i></div>
                        <InputText className="w-50" value={item.title} onChange={changeItem(index,"title")} placeholder="Title" />
                        <InputText className="w-50" value={item.subtitle} onChange={changeItem(index,"subtitle")} placeholder="Subtitle" />
                        <InputText className="w-50" value={item.price} onChange={changeItem(index,"price")} placeholder="Price Text" />
                        <InputText className="w-50" value={item.button} onChange={changeItem(index,"button")} placeholder="Button Label" />
                        <InputText className="w-full" value={item.url} onChange={changeItem(index,"url")} placeholder="URL link" />
                        <SelectButton className="flex-1" value={item.type} onChange={changeItem(index,"type")} optionLabel="name" options={[{name:"Accent", value:"accent"}, {name: "Large", value: "large"}]} multiple />
                        
                        <ImageUploader
                            className="flex-1" 
                            chooseLabel="Upload image to Card"
                            uploadCallback={changeItem(index, "pic")}
                            image_url={item.pic}
                        />
                    </div>
                ))}
            </div>
            <Button label="+ Add Card" text onClick={addObjectToCollection} />
        </Block>
    );
}




export default WhatsIn;