import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './Characteristics.scss';



/*

    {
        "type": "characteristics",
        "title": false,
        "bg_desktop": "images/characteristics.1.bg.desktop.webp",
        "bg_mobile": "images/characteristics.1.bg.mobile.webp",
        "items": [
            {
                "pic": "images/characteristics.1.item.pic.3.png",
                "title": "Resolution",
                "desc": "4K"
            },
            {
                "pic": "images/characteristics.1.item.pic.1.png",
                "title": "File formats",
                "desc": "16-bit Quicktime ProRes 4444 <br> Quicktime PNG <br> MP4 <br> PNG Sequence"
            },
            {
                "pic": "images/characteristics.1.item.pic.2.png",
                "title": "Framerate",
                "desc": "Up to 60fps"
            }
        ]
    },

*/

function Characteristics(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [bg_desktop, setBgDesktop] = useState(props.bg_desktop??'');
    const [bg_mobile, setBgMobile] = useState(props.bg_mobile??'');

    const [collection, setCollection] = useState(props.items??[]);
    const emptyObject = {pic: '', title: '', desc: ''};

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, title, bg_desktop, bg_mobile, items: collection});
        }
    }, [blockState, title, bg_desktop, bg_mobile, collection]);


    const addObjectToCollection = () =>{
        setCollection(prevCollection => {
            let newCollection = [...prevCollection];
            newCollection.push({...emptyObject});
            return newCollection;
        });
    }

    const deleteObjectFromCollection = (object_index) => {
        return () => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];
                newCollection.splice(object_index, 1);
                return newCollection;
            });
        };
    }

    const changeItem = (index, type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }else{
                    val='';
                }

                newCollection[index][type] = val;
                return newCollection;
            });
        }
    }



    return (
        <Block className="characteristics-block" title="Characteristics Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title Text" />
            <ImageUploader 
                chooseLabel="Choose a Desktop bg image"
                uploadCallback={setBgDesktop}
            />
            <ImageUploader 
                chooseLabel="Choose a Mobile bg image"
                uploadCallback={setBgMobile}
            />

            <div className='collection'>
                {collection.map((item, index) => (
                    <div className='item' key={`item-${index}`}>
                        <div>Item {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteObjectFromCollection(index)}></i></div>
                        <ImageUploader 
                            chooseLabel="Upload Item image"
                            uploadCallback={changeItem(index, "pic")}
                            image_url={item.pic}
                        />
                        <InputText value={item.title} onChange={changeItem(index,"title")} placeholder="Title" />
                        <InputText value={item.desc} onChange={changeItem(index,"desc")} placeholder="Description" />
                        
                    </div>
                ))}
            </div>
            <Button label="+ Add Item" text onClick={addObjectToCollection} />
        </Block>
    );
}




export default Characteristics;