import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './EffectsList.scss';



/*

    {
        "type": "effects-list",
        "accent": "VFX Assets",
        "title": "included",
        "see_all": "https://google.com/",
        "effects": [
            {
                "pic": "images/effects-list.pic.1.webp",
                "title": "Ground explosion"
            },
            {
                "pic": "images/effects-list.pic.1.webp",
                "title": "Ground explosion"
            },
        ]
    }

*/

function EffectsList(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [accent, setAccent] = useState(props.accent??'');
    const [see_all, setSeeAll] = useState(props.see_all??'');

    const [collection, setCollection] = useState(props.effects??[]);
    const emptyObject = {pic: '', title: ''};

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, title, accent, see_all, effects: collection});
        }
    }, [blockState, title, accent, see_all, collection]);


    const addObjectToCollection = () =>{
        setCollection(prevCollection => {
            let newCollection = [...prevCollection];
            newCollection.push({...emptyObject});
            return newCollection;
        });
    }

    const deleteObjectFromCollection = (object_index) => {
        return () => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];
                newCollection.splice(object_index, 1);
                return newCollection;
            });
        };
    }
    
    const changeItem = (index, type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }

                newCollection[index][type] = val;
                return newCollection;
            });
        }
    }



    return (
        <Block className="effects-list-block" title="Effects List Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <InputText value={accent} onChange={(e) => setAccent(e.target.value)} placeholder="Accent Text" />
            <InputText value={see_all} onChange={(e) => setSeeAll(e.target.value)} placeholder="See All's Text" />

            <div className='collection'>
                {collection.map((item, index) => (
                    <div className='item' key={`item-${index}`}>
                        <div>Effect {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteObjectFromCollection(index)}></i></div>
                        <ImageUploader 
                            chooseLabel="Upload Effect image"
                            uploadCallback={changeItem(index, "pic")}
                            image_url={item.pic}
                        />
                        <InputText value={item.title} onChange={changeItem(index,"title")} placeholder="Title" />
                        
                    </div>
                ))}
            </div>
            <Button label="+ Add Item" text onClick={addObjectToCollection} />
        </Block>
    );
}




export default EffectsList;