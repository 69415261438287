import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Password } from 'primereact/password';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primeicons/primeicons.css';

import './Login.scss';

const Login = ({visible, token, callbackSuccess, callbackFailure}) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code2fa, setCode2fa] = useState('');

  const [showCodeForm, setShowCodeForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    //console.log("Token when rendered login", token);
    if(token){
      const decodedToken = parseJwt(token);

      //console.log("TOKEN expire", new Date(decodedToken.exp * 1000) );
      if (decodedToken.exp * 1000 < Date.now()) {
        //console.log("Token expired");
        callbackFailure();
      }else{
        //console.log("Token valid");
        callbackSuccess({authorization: {token}, user: decodedToken.sub});
      } 

    }
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: email, password: password, code: code2fa }),
    };

    try {
      const response = await fetch('https://shark-qa.productioncrate.com/api/admin/login', requestOptions);
      const data = await response.json();
      console.log(data);
      setLoading(false);
      if(data.status == "error"){
        //setVisible(true);
        callbackFailure(data.message);
        setEmail('');
        setPassword('');
        setCode2fa('');
        setShowCodeForm(false);

      }else{
        //setVisible(false);
        callbackSuccess({authorization: data.authorization, user: data.user});
      }
      
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    const re = /^[\w-]+(\.[\w-]+)*(\+\w+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return re.test(email);
  };
  const isValidLogin = (em,pas) => {
    return isValidEmail(em)>0 && pas.length>7;
  };
  const isValidMFA = mfa => mfa>99999 && mfa<1000000;

  const nextStep = () =>{
    setShowCodeForm(true);
  }
  const prevStep = () =>{
    setShowCodeForm(false);
  }
  const renderFooter = () => {
    return (
      <div>
        {showCodeForm &&
        <Button label="Back" icon="pi pi-times" onClick={prevStep} className="p-button-text" />
        }
        <Button 
            label={showCodeForm?"Submit":"Sign In"} 
            icon="pi pi-check" 
            onClick={showCodeForm?handleSubmit:nextStep} 
            className="p-button-text"
            disabled={showCodeForm?!isValidMFA(code2fa):!isValidLogin(email,password)}
        />
      </div>
    );
  };

  return (
    <>
      <Dialog header={showCodeForm?"Multi-factor Authentication":"Login"} closable={false} visible={visible} style={{ width: '24em' }} footer={renderFooter} onHide={() => console.log("close")}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
            <ProgressSpinner />
          </div>
        ) : (
          <form onSubmit={showCodeForm?handleSubmit:nextStep}>
            
            <div className="p-fluid">
              {!showCodeForm && 
                <div className="p-field">
                    <label htmlFor="email">Username</label>
                    <InputText id="email" value={email} name="email" onChange={(e) => setEmail(e.target.value)} required />
                </div>
              }
              {!showCodeForm && 
                <div className="p-field">
                    <label htmlFor="password">Password</label>
                    <Password id="password" value={password} name="password" onChange={(e) => setPassword(e.target.value)} toggleMask required />
                </div>
             }
              {showCodeForm && (
                <div className="p-field">
                    <label htmlFor="code">MFA Code</label>
                    <InputText id="code" value={code2fa} keyfilter="int" maxLength="6" minLength="6" onChange={(e) => setCode2fa(e.target.value)} required />
                </div>
              )}
            </div>
          </form>
        )}
      </Dialog>
    </>
  );
};

export default Login;
