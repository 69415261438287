import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import './Cta.scss';



/*

    {
        "type": "cta",
        "claim": "Get this Pack with a Pro Membership",
        "btn_label": "register",
        "url": "https://www.productioncrate.com/register/"
    }

*/



function Cta(props) {

    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [claim, setClaim] = useState(props.claim??'');
    const [btn_label, setBtnLabel] = useState(props.btn_label??'');
    const [url, setUrl] = useState(props.url??'');

    const [blockState, setBlockState] = useState("initiated");


    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, btn_label, url, claim});
        }
    }, [blockState, btn_label, url, claim]);




    return (
        <Block className="cta-block" title="CTA Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText className="flex-1" value={claim} onChange={(e) => setClaim(e.target.value)} placeholder="Claim Text" />
            <InputText className="flex-1" value={btn_label} onChange={(e) => setBtnLabel(e.target.value)} placeholder="Button Label" />
            <InputText className="w-full" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Link URL" />
        </Block>
    );
}




export default Cta;