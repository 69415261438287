import { Ripple } from 'primereact/ripple';
import './Toolbox.scss';


function Toolbox() {

    const sendData = (event, blockType) => {

        event.dataTransfer.setData("text/plain", JSON.stringify({
            type: blockType,
            status: "new"
        }));
    };
    return (
        <div className='toolbox'>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Hero
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "hero")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Cta
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "cta")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Carousel
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "marquees")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Quick Links
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "quick-links")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                What's In
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "whats-in")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Characteristics
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "characteristics")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Effects List
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "effects-list")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Comparison
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "comparison")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Info
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "info")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Gallery
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "gallery")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Accordeon
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "accordeon")}
                    className="draggable"></div>
            </div>
            <div
                className="tool bg-primary flex select-none justify-content-center align-items-center shadow-2 border-round p-4 font-bold p-ripple">
                Audio List
                <Ripple />
                <div 
                    draggable
                    onDragStart={e => sendData(e, "audio-list")}
                    className="draggable"></div>
            </div>
        </div>
    );

}


export default Toolbox;