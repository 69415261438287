import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './Comparison.scss';



/*

    {
        "type": "comparison",
        "title": "Before and after",
        "subtitle": "Morbi ullamcorper posuere diam id mollis. Phasellus et malesuada mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus.",
        "comparisons": [
            {
                "left": "images/marquee.2.pic.4.webp",
                "right": "images/marquee.2.pic.5.webp"
            },
            {
                "left": "images/marquee.2.pic.4.webp",
                "right": "images/marquee.2.pic.5.webp"
            },
            {
                "left": "images/marquee.2.pic.4.webp",
                "right": "images/marquee.2.pic.5.webp"
            },
            {
                "left": "images/marquee.2.pic.4.webp",
                "right": "images/marquee.2.pic.5.webp"
            }
        ]
    },

*/

function Comparison(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [subtitle, setSubtitle] = useState(props.subtitle??'');

    const [collection, setCollection] = useState(props.comparisons??[]);
    const emptyObject = {left: '', right: ''};

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, title, subtitle, comparisons: collection});
        }
    }, [blockState, title, subtitle, collection]);


    const addObjectToCollection = () =>{
        setCollection(prevCollection => {
            let newCollection = [...prevCollection];
            newCollection.push({...emptyObject});
            return newCollection;
        });
    }

    const deleteObjectFromCollection = (object_index) => {
        return () => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];
                newCollection.splice(object_index, 1);
                return newCollection;
            });
        };
    }

    const changeItem = (index, type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }

                newCollection[index][type] = val;
                return newCollection;
            });
        }
    }



    return (
        <Block className="comparison-block" title="Comparison Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <InputText value={subtitle} onChange={(e) => setSubtitle(e.target.value)} placeholder="Subtitle" />

            <div className='collection'>
                {collection.map((item, index) => (
                    <div className='item' key={`item-${index}`}>
                        <div>Comparison {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteObjectFromCollection(index)}></i></div>
                        <ImageUploader 
                            chooseLabel="Left image"
                            uploadCallback={changeItem(index, "left")}
                            image_url={item.left}
                        />
                        <ImageUploader 
                            chooseLabel="Right image"
                            uploadCallback={changeItem(index, "right")}
                            image_url={item.right}
                        />
                    </div>
                ))}
            </div>
            <Button label="+ Add Comparison" text onClick={addObjectToCollection} />
        </Block>
    );
}




export default Comparison;