import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './Gallery.scss';



/*

    {
        "type": "gallery",
        "bg_desktop": "images/info.2.bg.desktop.webp",
        "bg_mobile": "images/info.2.bg.mobile.webp",
        "title": "Photo gallery",
        "pics": [
            "images/marquee.1.pic.1.webp",
            "images/marquee.1.pic.2.webp",
            "images/marquee.1.pic.3.webp",
            "images/marquee.1.pic.4.webp",
            "images/marquee.1.pic.5.webp",
            "images/marquee.2.pic.1.webp",
            "images/marquee.2.pic.2.webp",
            "images/marquee.2.pic.3.webp",
            "images/marquee.2.pic.4.webp",
            "images/marquee.2.pic.5.webp"
        ]
    }

*/

function Gallery(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [bg_desktop, setBgDesktop] = useState(props.bg_desktop??'');
    const [bg_mobile, setBgMobile] = useState(props.bg_mobile??'');

    const [pics, setPics] = useState(props.pics??[]);

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");
        
        if(updateCallback){
            updateCallback({status:blockState, title, bg_desktop, bg_mobile, pics});
        }
    }, [blockState, title, bg_desktop, bg_mobile, pics]);

    const deletePic = (pic_index) => {
        setPics(prevPics => {
            let newPics = [...prevPics];
            newPics.splice(pic_index, 1);
            return newPics;
        });
    };

    const addPic = (img) => {

        setPics(prevPics => {
            let newPics = [...prevPics];

            newPics = [...prevPics, ...img];
            return newPics;
        });
    }

    return (
        <Block className="gallery-block" title="Gallery Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <ImageUploader 
                chooseLabel="Choose a Desktop bg image"
                uploadCallback={setBgDesktop}
            />
            <ImageUploader 
                chooseLabel="Choose a Mobile bg image"
                uploadCallback={setBgMobile}
            />
            <div className='pic-list'>
                {pics.map((pic, pic_index) => (
                    <div className="pic" key={`pics-${pic_index}`} >
                        <img src={pic} />
                        <i className="delete-pic pi pi-trash error" onClick={() => deletePic(pic_index)}></i>
                    </div>
                ))}
            </div>
            <ImageUploader 
                chooseLabel="Upload pics to the gallery"
                multiple={true}
                uploadCallback={imgs => addPic(imgs)}
                image_url={pics}
            />
        </Block>
    );
}




export default Gallery;