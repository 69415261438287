import React, {useRef} from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import './Block.scss';


function Block({children, title, className, status, deleteCallback, moveCallback}) {
    const op = useRef(null);

    return (
        <div className={`builder-block ${className} ${status} p-card`} >
            <OverlayPanel ref={op}>
                <img src={`/images/${className}.png`} style={{width:"50vw"}} alt={className}></img>
            </OverlayPanel>
            <div className="controls p-card-footer">
                <div className="control" onClick={() => moveCallback(-1)}>
                    <i className="pi pi-angle-up" ></i>
                </div>
                <div className="control" onClick={() => moveCallback(1)}>
                    <i className="pi pi-angle-down" ></i>
                </div>
                <div className="delete control" onClick={deleteCallback}>
                    <i className="pi pi-trash" ></i>
                </div>
            </div>
            <div className="status-icon">
                <i className="pi pi-image" onClick={(e) => op.current.toggle(e)}></i>
                { status=="completed" && <i className="pi pi-check completed"></i>}
                { status=="error" && <i className="pi pi-info-circle error"></i>}
                { status=="initiated" && <i className="pi pi-file initiated"></i>}
            </div>
            <h5 className='p-card-title w-full text-left'>{title}</h5>
            {children}
        </div>
    );
}




export default Block;