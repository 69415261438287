
import React, {useState, useEffect, useRef, useImperativeHandle, forwardRef} from 'react';
import {Hero, Cta, Carousel, Links, WhatsIn, Characteristics, EffectsList, Comparison, Info, Gallery, Accordeon, AudioList} from './Blocks';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { ImageUploader } from '../ImageUploader';
import './Canvas.scss';


//ToDO: this should part of util
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0;
      const v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  

function Canvas({isDragging, callbackFailure, updatePreview}, ref) {
    //const [isDragging, setIsDragging] = useState(false);
    const [pageId, setPageId] = useState(null);
    const [blocks, setBlocks] = useState([]);
    const [pageTitle, setPageTitle] = useState("");
    const [pageName, setPageName] = useState("");
    const [pageCategory, setPageCategory] = useState('');

    const [pageMetaName, setPageMetaName] = useState("");
    const [pageMetaDescription, setPageMetaDescription] = useState("");
    const [pageMetaKeywords, setPageMetaKeywords] = useState("");
    const [pageMetaImage, setPageMetaImage] = useState("");



    const pageCategories = [
        {label:'VFX', value:'VFX'},
        {label:'Sound-Effects', value: 'Sound-Effects'},
        {label: 'Royalty-Free-Music', value: 'Royalty-Free-Music'},
        {label: '3DModels', value: '3DModels'},
        {label:'VFX-Software', value:'VFX-Software'},
        {label: 'graphics', value: 'graphics'},
        {label: 'stock-images', value: 'stock-images'},
    ];

    const [finalUrl, setFinalUrl] = useState(null);

    const [showResults, setShowResults] = useState(false);

    const [isDeploying, setIsDeploying] = useState(false);


    const canvasRef = useRef(null);

    const toast = useRef(null);
    
    const iframePreviewData = msg => `<html><body style="background-color:#121212; color: #898989; font-size: 1.7rem;font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif">
        <div style="display:flex;align-items:center;justify-content:center;position:absolute;top:0;bottom:0;left:0;right:0;margin:auto;padding-top:8rem;">
        ${msg}
        </div>
    </body></html>`;

    const updateIframe = html => {
        if(updatePreview){
            updatePreview(html);
        }
        
    }

    useImperativeHandle(ref, () => ({
        clearCanvas(){
            setPageId(null);
            setPageTitle('');
            setPageName('');
            setPageCategory('');
            setPageMetaName('');
            setPageMetaDescription('');
            setPageMetaKeywords('');
            setPageMetaImage('');
            setBlocks([]);
        },
        updateCanvas({id, name, blocks, head}) {

            setPageId(id);
            setPageTitle(head.title??'');
            setPageName(name);
            setPageCategory(head.category??'VFX');
            
            setPageMetaName(head.name??'');
            setPageMetaDescription(head.description??'');
            setPageMetaKeywords(head.keywords??'');
            setPageMetaImage(head.image??'');
            
            setBlocks(blocks.map(el => ({id: generateUUID(), ...el})));
        },
        resetPreview(){
            updateIframe(iframePreviewData("No preview Available yet..."));
        }
    }));
    useEffect(() => {
        updateIframe(iframePreviewData("No preview Available yet..."));
    }, []);

    const generatePreview = async () => {
        const url = 'https://shark-qa.productioncrate.com/api/landings/preview';
        const pageData = formatData();
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
            },
            body: JSON.stringify(pageData)
        };
        let previewDefault = iframePreviewData("Generating Preview...");

        updateIframe(previewDefault);
        try{
            let response = await fetch(url, options);
            let data = await response.json();
            if(data.html){
                        
                updateIframe(data.html);

            }else{
                previewDefault = iframePreviewData("Preview Build Failed, correct blocks and try again."); 

                updateIframe(previewDefault);
                if(data.message.contains("Unauthenticated")){
                    callbackFailure({sessionExpired: true});
                }
                
            }

        }catch(e){
            console.log("ERROR!", e);
            callbackFailure();
            return;
        }
    };

    const addNewBlock = (block) => {
        block.status = "initiated";
        block.id = generateUUID();

        setBlocks(prevBlocks => [...prevBlocks, block]);

    };

    const deleteBlock = (index) => {

        setBlocks(prevBlocks => ([...prevBlocks.filter((_, i) => i !== index)]));

    };
    
    const updateBlock = (index) => {

        return (data) => {
            setBlocks(prevBlocks => {
                let newBlocks = [...prevBlocks];
                newBlocks[index] = {...newBlocks[index], status: data.status, data: data};
                return newBlocks;
            });
        };
    };
    const moveBlock = (index) => {

        return (n) => {

            setBlocks(prevBlocks => {


                const newBlocks = [...prevBlocks];
                const newPosition = index + n;

                // Verificar si la nueva posición está dentro de los límites del array
                if (newPosition < 0 || newPosition >= newBlocks.length) {
                    console.log('No se puede mover el bloque');
                    return prevBlocks;
                }

                // Reordenar el array utilizando splice()
                const block = newBlocks.splice(index, 1)[0];
                newBlocks.splice(newPosition, 0, block);

                // Actualizar el estado 'blocks' con el nuevo array
                return newBlocks;



            });
        };
    };


    const handleDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
    };



    const handleDrop = e => {
        e.preventDefault();
        e.stopPropagation();

        if(!e.dataTransfer.getData("text")){
            return;
        }
        
        let block = JSON.parse(e.dataTransfer.getData("text"));

        addNewBlock(block);
        //console.log("INITIA:",canvasRef.current.scrollHeight);
        setTimeout(() => {
            //console.log("FINAL",canvasRef.current.scrollHeight);
            window.scrollTo({
                top: canvasRef.current.scrollHeight,
                behavior: "smooth",
            });
        }, 100);
    };
    
    const displayResults = () => {
        setShowResults(true);
    };

    const closeResults = () => {
        setShowResults(false);
    };

    // Recursive function to update properties from empty to boolean false
    const falsifyEmptyProperties = (obj) => {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursive call for nested objects or arrays
                falsifyEmptyProperties(obj[key]);
            } else if (obj[key] === '') {
                // Replace empty string with false
                obj[key] = false;
            }
        });
        return obj;
    };
    const formatData = () => {
        const pageData = {
            name: !!pageName?pageName:"preview name",
            head: {
                title: !!pageTitle?pageTitle:"title preview", 
                category: !!pageCategory?pageCategory:"preview category",
                name: !!pageMetaName?pageMetaName:"preview meta",
                description: !!pageMetaDescription?pageMetaDescription:"preview description",
                keywords: !!pageMetaKeywords?pageMetaKeywords:"preview keywords",
                image: !!pageMetaImage?pageMetaImage:"preview image"
            },
            blocks: blocks.map(b=>({type:b.type,...falsifyEmptyProperties(b.data)}))
        };
        return pageData;
    };
    const deployPage = async () => {
        if(pageTitle.length === 0 || pageName.length===0 || pageCategory.length===0 || blocks.length===0){
            return;
        }
        const pageData = formatData();

        let url = 'https://shark-qa.productioncrate.com/api/landings';
        if(pageId){
            url += `/${pageId}`;
        }
        const options = {
            method: pageId?'PUT':'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
            },
            body: JSON.stringify(pageData)
        };

        setIsDeploying(true);

        try{
            let response = await fetch(url, options);
            response = await response.json();
            if(response.message && response.message.contains("Unauthenticated")){
                showError();
                setIsDeploying(false);
                setFinalUrl(null);
                callbackFailure({sessionExpired: true});
                return;
            }
        }catch(e){
            console.log(e);
            showError();
            setIsDeploying(false);
            setFinalUrl(null);
            callbackFailure();
            return;
        }
        showSuccess();
        setFinalUrl(`${pageCategory}/${pageName}.html`);
        setIsDeploying(false);
    }
    const getLabelMessage = blocks => {

        if(blocks.length === 0){
            return "Add a block to start";
        }
        if(blocks.filter(b=>b.status==='completed').length<blocks.length){
            console.log("BLOCKS:",blocks.map(b=>b.status));
            return `Please review, ${blocks.filter(b=>b.status!=='completed').length} out of ${blocks.length} blocks seems to be incomplete.`;
        }
        
        return `We are ready to go! Hit Deploy and create a ${blocks.length} blocks page.`;
        
    };
    const handlePageNameKeyPress = (event) => {
        const regex = /[a-zA-Z0-9_-]/;
        const key = event.key;
        if (!regex.test(key)) {
            event.preventDefault();
        }
    };

    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Page deployed succesfully!', life: 3000});
    };

    const showError = () => {
        toast.current.show({severity:'error', summary: 'Error', detail:'Something went wrong while deploying.', life: 3000});
    };


    return (
        <div className="canvas ml-auto flex-1">
             <Toast ref={toast} />
            <div 
                className={`droppable-region flex flex-column ${isDragging ? 'dragging-over' : ''}`}
                onDrop={e => handleDrop(e)}
                onDragOver={e => handleDragOver(e)} 
                ref={canvasRef}
            >
                {blocks && blocks.map( (block, index) => {
                    switch(block.type) {
                        case "hero":
                            return <Hero 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "cta":
                            return <Cta 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "marquees":
                            return <Carousel 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "quick-links":
                            return <Links 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "whats-in":
                            return <WhatsIn 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "characteristics":
                            return <Characteristics 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "effects-list":
                            return <EffectsList 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "comparison":
                            return <Comparison 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "info":
                            return <Info 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "gallery":
                            return <Gallery 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "accordeon":
                            return <Accordeon 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        case "audio-list":
                            return <AudioList 
                                        {...block}
                                        key={block.id}  
                                        moveCallback={moveBlock(index)}
                                        deleteCallback={()=>deleteBlock(index)} 
                                        updateCallback={updateBlock(index)}
                                    />;
                        default:
                            return null;
                    }
                })}
                {blocks.length === 0 &&
                    <div className="empty-state">

                        <div className="icon mb-5">
                            <i className="pi pi-clone"></i>
                        </div>
                        <div className="empty-state__text">
                            <h3>Drag and drop a block to start</h3>
                            <p>Blocks are the building blocks of your page. You can add as many as you want.</p>
                        </div>
                    </div>
                }
            </div>
            <div className={`toolbar p-4 ${showResults?'expanded':''}`}>
                { !showResults &&
                    <>
                        <div className='label'>
                            {getLabelMessage(blocks)}
                        </div>
                        <Button 
                            severity="secondary"
                            label="Generate Preview" 
                            disabled={blocks.length===0 || blocks.filter(b=>b.status==='completed').length<blocks.length} 
                            onClick={() => generatePreview()}
                        />
                        <Button 
                            label="Deploy" 
                            disabled={blocks.length===0 || blocks.filter(b=>b.status==='completed').length<blocks.length} 
                            onClick={displayResults}
                        />
                    </>
                }
                { showResults &&
                    <Panel className="panel" header="Product Page Deployment">
                        <div className='page-details'>
                            <InputText disabled={isDeploying || pageId} required placeholder="Page Title" value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} />
                            <InputText disabled={isDeploying || pageId} placeholder="page-name" value={pageName} onChange={(e) => setPageName(e.target.value)} onKeyPress={handlePageNameKeyPress} />
                            <Dropdown disabled={isDeploying || pageId} className="dropdown-placeholder w-full md:w-14rem" value={pageCategory} onChange={(e) => setPageCategory(e.value)} options={pageCategories} optionLabel="label" placeholder="Page Folder" />
                            
                            <InputText disabled={isDeploying || pageId} required placeholder="META Name" value={pageMetaName} onChange={(e) => setPageMetaName(e.target.value)} />
                            <InputText disabled={isDeploying || pageId} required placeholder="META Descripcion" value={pageMetaDescription} onChange={(e) => setPageMetaDescription(e.target.value)} />
                            <InputText disabled={isDeploying || pageId} required placeholder="META Keywords" value={pageMetaKeywords} onChange={(e) => setPageMetaKeywords(e.target.value)} />
                            
                            <ImageUploader 
                                className="ml-auto" 
                                chooseLabel="META image 1920x1080"
                                uploadCallback={setPageMetaImage}
                            />
                        </div>
                        <div className='footer'>
                            {finalUrl && 
                            <a className='url-link mr-auto' href={`https://www.productioncrate.com/${finalUrl}`} target='_blank' rel="noreferrer">
                                {finalUrl}
                            </a>
                            }
                            {isDeploying && 
                                <ProgressSpinner style={{width: '50px', height: '50px'}} className="m-0" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            }
                            {!isDeploying && 
                                <Button 
                                    label="Deploy" 
                                    onClick={() => deployPage()}
                                />  
                            }
                            <Button 
                                disabled={isDeploying}
                                severity="danger"
                                label="Close" 
                                onClick={closeResults}
                            />
                        </div>
                    </Panel>
                }
            </div>
            { false && showResults && 
            <div className="results-modal">
                
            </div>
            }
        </div>
    );

}


export default forwardRef(Canvas);