import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import './Pages.scss';

function Pages({visible, onClose, loadPage, callbackFailure}) {
  const [items, setItems] = useState([]);

  
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    reloadPages();
  }, []);

  const reloadPages = async () => {
    setLoading(true);
    setSelectedItem(null);
    const url = 'https://shark-qa.productioncrate.com/api/landings';
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        }
    };

    try{
      const response = await fetch(url, options)
      const data = await response.json();
      
      console.log({data});
      if(data.message && data.message.contains("Unauthenticated")){
        callbackFailure({sessionExpired: true});
        return;
      }else{
        setItems(data.map(page => ({label: `${page.head.category??'VFX'} / ${page.name}.html`, name: page.name, value: page.id})));
      }
    }catch(error){
      callbackFailure(error);
    }
    setLoading(false);
  };

  const deletePage = async id => {
    setLoading(true);
    
    const url = `https://shark-qa.productioncrate.com/api/landings/${selectedItem}`;
    const options = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        }
    };
    setSelectedItem(null);
    
    await fetch(url, options);
    setLoading(false);
    //const data = await response.json();

    await reloadPages();
  };

  const confirmDelete = (selectedItem) => {
    console.log("CONFIRM DELETE", selectedItem);
    confirmDialog({
        message: 'Do you want to delete this page?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => deletePage(selectedItem),
    });
  };

  const editPage = async id => {
    setLoading(true);
    
    const url = `https://shark-qa.productioncrate.com/api/landings/${selectedItem}`;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        }
    };
    setSelectedItem(null);
    
    const response = await fetch(url, options);
    const data = await response.json();
    setLoading(false);
    if(data.message && data.message.contains("Unauthenticated")){
      callbackFailure({sessionExpired: true});
      return;
    }
    loadPage(data)
  };

  const duplicatePage = async id => {
    setLoading(true);
    
    const url = `https://shark-qa.productioncrate.com/api/landings/${selectedItem}`;
    const options = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        }
    };
    setSelectedItem(null);
    
    const response = await fetch(url, options);
    let data = await response.json();
    setLoading(false);
    data.id = null;
    data.head.title = '';
    data.name = '';
    loadPage(data)
  };



  const visitPage = id => {
    const selectedPage = items.find(page => page.value === id);

    window.open(`https://www.productioncrate.com/VFX/${selectedPage.name}.html`, '_blank');
  }
  const pageTemplate = (option) => {
    return (
      <div>{option.label}</div>
    );
  };
  

  const dialogFooter = (
    <div>
      <Button onClick={()=>editPage(selectedItem)} disabled={!!!selectedItem} severity="primary">Edit</Button>
      <Button onClick={() => confirmDelete(selectedItem)} disabled={!!!selectedItem} severity="danger">Delete</Button>
      <Button onClick={()=>duplicatePage(selectedItem)} disabled={!!!selectedItem} severity="primary">Duplicate</Button>
      <Button onClick={() => visitPage(selectedItem)} disabled={!!!selectedItem} severity="primary">Visit</Button>
      <Button onClick={onClose} severity="iwarningnfo" style={{marginLeft: '2rem'}}>Cancel</Button>
    </div>
  );

  return (
    <Dialog
      header="Pages Admin"
      visible={visible}
      modal
      onHide={onClose}
      footer={dialogFooter}
      className={`pages_dialog`}
    >
    <ConfirmDialog />
    {!loading &&
    <ListBox
        value={selectedItem}
        options={items}
        filter
        filterPlaceholder="Filter pages..."
        itemTemplate={pageTemplate}
        onChange={(e) => {console.log("LIST VALUE CHANGE", e.value);setSelectedItem(e.value)}} // Asegúrate de que el valor del estado siempre se actualice
    />
    }
    {loading &&
      <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
    }
    </Dialog>
  );
}


export default Pages;
