import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from "uuid";
import { FileUpload } from 'primereact/fileupload';
import uploadImage from '../../services/uploadImage';
import { Button } from 'primereact/button';

import './ImageUploader.scss';

const ImageUploader = ({chooseLabel="Choose",image_url, multiple=false, uploadCallback, className=''}) => {
  const fileUploadRef = useRef(null);

  const [isUploading, setIsUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(image_url??null);

  useEffect(() => {
    if(!window.ml){
      //If it's not initiated, init them globally
      window.ml = window.cloudinary.createMediaLibrary({
          cloud_name: 'drx5sj1j7',
          api_key: '658131837416436',
          insert_caption: 'Select IMG',
        },{
          insertHandler: data=> window.mlCallback(data.assets)
        }
      );
    }
  }, []);

  useEffect(() => {
    console.log({imageUrl});
  }, [imageUrl]);

  const openMediaLibrary = () => {
    window.mlCallback = result => {
      console.log("ASSETS", result);
      if(multiple){
        setImageUrl(result.map(r=>r.secure_url));
        uploadCallback(result.map(r=>r.secure_url));
      }else{
        setImageUrl(result[0].secure_url);
        uploadCallback(result[0].secure_url);
      }

    };
    window.ml.show({multiple});
  };

  const getSignature = async (public_id) => {
    //`http://localhost:5001/cloudinary/signature?public_id=${public_id}`
    const response = await fetch(
      `https://shark-qa.productioncrate.com/api/landings/cloudinary-signature/?public_id=${public_id}`,
      {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        },
      }
    );
    const data = await response.json();

    if(data.message && data.message.contains("Unauthenticated")){
        console.log("Session expired");
        return;
    }
    return data;
  };

  const onUpload = async (event) => {
    const files = event.files;
    let signaturePromises = [];
    let uploadPromises = [];
    let signatures = [];

    setIsUploading(true);

    try {
      for (const file of files) {
        const uniqueFilename = uuidv4();
        signatures.push({uniqueFilename, signature: null, timestamp: null});

        signaturePromises.push(getSignature(uniqueFilename));
      }

      let signaturesResult = await Promise.all(signaturePromises);
      
      signatures = signatures.map((s, i)=>({...s, signature: signaturesResult[i].signature, timestamp: signaturesResult[i].timestamp}));


      for (const [i, file] of files.entries()) {
        uploadPromises.push(uploadImage(file, signatures[i].uniqueFilename, signatures[i].signature, signatures[i].timestamp));
      }

      
      const result = await Promise.all(uploadPromises);
      console.log('Image uploaded:', [...result]);
      
      if(multiple){
        setImageUrl(result.map(r=>r.secure_url));
        uploadCallback(result.map(r=>r.secure_url));
      }else{
        setImageUrl(result[0].secure_url);
        uploadCallback(result[0].secure_url);
      }


    } catch (error) {
      console.error('Error uploading image:', error);
    }

    // Limpia el componente FileUpload después de subir las imágenes
    fileUploadRef.current.clear();
    setIsUploading(false);
  };

  /*

    window.ml = cloudinary.createMediaLibrary({
      cloud_name: 'drx5sj1j7',
      api_key: '658131837416436',
      remove_header: false,
      max_files: '1',
      insert_caption: 'Insert',
      inline_container: '#widget_container',
      default_transformations: [
        []
      ],
      button_class: 'myBtn',
      button_caption: 'Select Image or Video'
    }, {
      insertHandler: function (data) {
        data.assets.forEach(asset => { console.log("Inserted asset:",
          JSON.stringify(asset, null, 2)) })
        }
      },
      document.getElementById("open-btn")
    );

    window.ml = cloudinary.createMediaLibrary({
      cloud_name: 'drx5sj1j7',
      api_key: '658131837416436',
      button_class: 'myBtn',
      button_caption: 'Select Image or Video',
    }, {
        insertHandler: function (data) {
          data.assets.forEach(asset => { console.log("Inserted asset:",
          JSON.stringify(asset, null, 2)) })
          }
        }
    );
  */

  return (
    <div className={`image-uploader ${className}`} >
      <Button 
          label={chooseLabel?chooseLabel:"Upload"} 
          onClick={openMediaLibrary} 
          className="p-button-text"
      />
      {false && isUploading && 
        <i className="pi pi-spin pi-spinner" style={{ fontSize: '1.5rem' }}></i>
      }
      {!multiple && imageUrl &&
        <img src={imageUrl} alt="Uploaded" className="pic-preview" />
      }
      { false && 
        <FileUpload
          ref={fileUploadRef}
          auto
          mode="basic" 
          customUpload
          uploadHandler={onUpload}
          accept="image/*"
          multiple={multiple}
          disabled={isUploading}
          chooseLabel={chooseLabel}
          onProgress={e => console.log('Progress:', e.progress)}
        />
      }
    </div>
  );
};

export default ImageUploader;
