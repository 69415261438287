import React, {useState, useEffect, useRef} from 'react';
import Block from './Block';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { ImageUploader } from '../../ImageUploader';
import './AudioList.scss';



/*

    {
        "type": "audio-list",
        "title": "Audio",
        "subtitle": "Most popular",
        "audios": [
            {
                "name": "Song name",
                "pic": "images/audio-list.song.1.pic.png",
                "waveform": [14.7173,27.5196,34.3771,45.0156,42.8428,57.2015,59.7116,47.8676,49.3553,49.7368,49.749,59.8642,62.298,56.7071,54.3557,45.3986,54.8089,54.255,49.4591,60.6561,61.4405,57.4578,48.6213,55.0164,51.7052,59.3286,58.1827,57.9553,63.1601,45.8335,56.0418,51.1513,54.9004,52.9091,63.4165,59.1379,45.2979,53.518,49.2363,65.0935,56.4294,59.6796,66.1036,46.627,49.099,42.4033,56.8109,45.2003,56.8139,58.4756,46.807,52.7199,45.1209,56.6339,58.9425,61.0376,53.9681,47.2953,48.9311,51.7159,57.9538,48.8273,59.8169,60.8072,58.0407,55.7488,53.0327,55.7321,58.2055,61.767,70.0359,59.44,48.3299,69.9596,42.5254,41.1429,68.397,56.9299,40.383,53.2418,71.2596,49.955,34.6014,48.1636,60.3357,72.9183,56.3149,64.976,55.6191,48.7144,66.0121,65.2094,50.8629,56.0342,68.5756,56.4599,45.2613,52.726,68.0339,69.5308,52.1614,51.8044,44.8402,53.0114,50.8019,63.4562,60.1678,45.478,50.0999,53.0236,59.0997,55.8862,57.0016,64.4755,54.0764,55.5291,49.7017,55.7397,48.5756,64.6464,60.1999,53.2311,50.5058,51.9066,57.6959,60.9903,62.7451,62.179,60.9369,49.926,53.1502,56.6461,52.5246,55.1537,60.9339,59.4278,48.9708,56.1273,51.0353,64.5853,60.8118,61.1032,65.4185,27.9408,6.9123,2.739,1.8433,1.3397,1.0452,0.8423],
                "preview": "https://cdn.staticcrate.com/stock-hd/audio/SoundsCrate-Heavy_Steps.mp3",
                "formats": [
                    {
                        "type": "wav",
                        "url": "https://cdn.staticcrate.com/stock-hd/audio/SoundsCrate-Heavy_Steps.wav"
                    },
                    {
                        "type": "mp3",
                        "url": "https://cdn.staticcrate.com/stock-hd/audio/SoundsCrate-Heavy_Steps.mp3"
                    }
                ]
            },
        ]
    }

*/

function AudioList(props) {
    let {id, type, status, deleteCallback, moveCallback, updateCallback} = props;

    const [title, setTitle] = useState(props.title??'');
    const [subtitle, setSubtitle] = useState(props.subtitle??'');

    const [collection, setCollection] = useState(props.audios??[]);
    const emptyObject = {name: '', pic: '', waveform: '', preview: '', formats: [{"type": "wav", "url": ""}, {"type": "mp3", "url": ""}]};

    const [blockState, setBlockState] = useState("initiated");
    

    useEffect(() => {
        setBlockState("completed");

        if(updateCallback){

            let collection_copy = JSON.parse(JSON.stringify(collection));

            collection_copy.forEach(item => {
                if(item.waveform.length > 0){
                    try{
                        item.waveform = eval(item.waveform);
                    }catch(e){
                        item.waveform = [];
                    }
                }
            });

            updateCallback({status:blockState, title, subtitle, see_all: "https://soundscrate.com/", audios: collection_copy});
        }
    }, [blockState, title, subtitle, collection]);


    const addObjectToCollection = () =>{
        setCollection(prevCollection => {
            let newCollection = [...prevCollection];
            newCollection.push({...emptyObject});
            return newCollection;
        });
    }

    const deleteObjectFromCollection = (object_index) => {
        return () => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];
                newCollection.splice(object_index, 1);
                return newCollection;
            });
        };
    }

    const changeItem = (index, type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }

                newCollection[index][type] = val;
                return newCollection;
            });
        }
    }

    const changeSubItem = (index, type, subitem_index, subitem_type) =>{

        return (val) => {
            setCollection(prevCollection => {
                let newCollection = [...prevCollection];

                if(val.target){
                    val = val.target.value;
                }

                newCollection[index][type][subitem_index][subitem_type] = val;
                return newCollection;
            });
        }
    }

    return (
        <Block className="audio-list-block" title="Audio List Block" status={blockState} deleteCallback={deleteCallback} moveCallback={moveCallback}>
            <InputText value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
            <InputText value={subtitle} onChange={(e) => setSubtitle(e.target.value)} placeholder="Subtitle" />

            <div className='collection'>
                {collection.map((item, index) => (
                    <div className='item' key={`item-${index}`}>
                        <div>Audio {index+1} <i className="delete-pic pi pi-trash error" onClick={deleteObjectFromCollection(index)}></i></div>
                        <InputText value={item.name} onChange={changeItem(index,"name")} placeholder="Title" />
                        <InputText value={item.preview} onChange={changeItem(index,"preview")} placeholder="Preview mp3 url" />
                        <ImageUploader 
                            chooseLabel="Upload Cover image"
                            uploadCallback={changeItem(index, "pic")}
                            image_url={item.pic}
                        />
                        <InputTextarea autoResize value={item.waveform} onChange={changeItem(index, "waveform")} rows={5} cols={60} />
                        <InputText value={item.formats[0].url} onChange={changeSubItem(index,"formats", 0, "url")} placeholder="Full WAV url" />
                        <InputText value={item.formats[1].url} onChange={changeSubItem(index,"formats", 1, "url")} placeholder="Full MP3 url" />
                    </div>
                ))}
            </div>
            <Button label="+ Add Audio" text onClick={addObjectToCollection} />
        </Block>
    );
}




export default AudioList;