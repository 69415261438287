import React, {useState, useEffect, useRef} from 'react';
import './styles/App.scss';
import logo from './logo.svg';
import { Toolbox } from './components/Toolbox';
import { Canvas } from './components/Canvas';
import { Login } from './components/Login';
import { Pages } from './components/Pages';
import { SpeedDial } from 'primereact/speeddial';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';

function App() {

  const [isDragging, setIsDragging] = useState(false);
  const [loginVisible, setLoginVisible] = useState(true);
  const [iframeFullScreen, setIframeFullScreen] = useState(false);
  const [pagesAdminVisible, setPagesAdminVisible] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('userToken'));

  const menu = useRef(null);

  const canvasRef = useRef(null);

  const items = [
    {
      label: 'New Page',
      icon: 'pi pi-fw pi-file',
      command: () => clearCurrentPage()
    },
    {
      label: 'Manage Pages',
      icon: 'pi pi-fw pi-book',
      command: () => setPagesAdminVisible(true)
    },
    {
      label: 'Logout',
      icon: 'pi pi-fw pi-power-off',
      command: () => logout()
    }
  ];


  const updatePreview = html => {
    var iframe = document.getElementById('preview-iframe');
    iframe.srcdoc = html;
  };

  const clearCurrentPage = () => {
    canvasRef.current.clearCanvas();
    canvasRef.current.resetPreview();
    setPagesAdminVisible(false);
  }

  const loadPage = (page) => {
    console.log("LOAD PAGE", page);
    canvasRef.current.updateCanvas(page);
    canvasRef.current.resetPreview();
    setPagesAdminVisible(false);
  };

  const loginSuccess = (data) => {
    setLoginVisible(false);
    localStorage.setItem('userToken', data.authorization.token);
  }

  const loginFailure = (error) => {
    console.log(error);
    if(error && error.sessionExpired){
      logout();
    }
  }

  const logout = () => {
    //localStorage.removeItem('userToken');
    //console.log("Remove expired token");
    setLoginVisible(true);
  }

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    //console.log("TOKEN", token);
    
    setToken(token);

  }, []);

  const toggleIframePreview = () => {
    setIframeFullScreen(!iframeFullScreen);
  };



  return (
    <div className="App grid m-0">
      
      <Login visible={loginVisible} token={token} callbackSuccess={loginSuccess} callbackFailure={loginFailure} />
      {!loginVisible && 
      <Pages visible={pagesAdminVisible} onClose={() => setPagesAdminVisible(false)} loadPage={loadPage} callbackFailure={loginFailure} />
      }
      {!iframeFullScreen &&
      <div className="sidebar p-2 fixed left-0 top-0 h-screen" 
        onDragStart={e => setIsDragging(true)}
        onDragEnd={e => setIsDragging(false)}
      >
        <h1 className="logo m-3 flex align-items-center gap-3">
          <img src={logo} /> ProductionCrate
        </h1>
        <h2 className="m-3 mt-6">Building Blocks</h2>
        <Toolbox />
      </div >
      }
      {!iframeFullScreen &&
      <div className="header ml-auto p-4 flex fixed right-0 top-0 ">
        <TieredMenu model={items} popup ref={menu} breakpoint="767px" />
        <Button icon="pi pi-bars" onClick={(e) => menu.current.toggle(e)} />
      </div>
      }
      <div className="content ml-auto p-0 flex relative">
        <Canvas isDragging={isDragging} ref={canvasRef} callbackFailure={loginFailure} updatePreview={updatePreview} />
        <div className="preview flex flex-1 sticky h-full top-0">
          <SpeedDial onClick={toggleIframePreview} radius={120} type="quarter-circle" buttonClassName="p-button-warning"  rotateAnimation={false} showIcon={`pi ${iframeFullScreen?'pi-window-minimize':'pi-window-maximize'}`} direction="down-left" style={{zIndex: '101', right: '1rem', top: '1rem' }} />
          <iframe id="preview-iframe" frameBorder="0" src="data:text/html;charset=utf-8,%3Chtml%3E%3Cbody%3Efoo%3C/body%3E%3C/html%3E" className={`flex-1 w-full flex-1 left-0 top-0 pb-8 ${iframeFullScreen?'full-screen':''}`} >
          </iframe>
        </div>
      </div >
    </div>
  );
}

export default App;
